import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { OnboardingStep } from './GettingStartedView'
import styled from 'styled-components'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import { convertToTitleCase } from 'employee-platform/shared/utils'
import RadioV2 from 'core-system/RadioV2'
import { useDispatch } from 'react-redux'
import { employerActions } from 'redux/employer/employerSlice'
import { FinchWorksite } from 'redux/employer/employerTypes'
import Divider from 'core-system/Divider'
import employerService from 'redux/employer/employerService'
import { employeeActions } from 'redux/employee/employeeSlice'
import palette from 'core-system/Themes/palette'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessMsgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.green3};
  > div {
    color: ${(props) => props.theme.palette.secondary.green1};
  }
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 50%;
`

const RadioListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const FinchWorksitesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  // overflow: overlay;
  // overflow-y: scroll;
  scrollbar-width: none;
`

interface OnboardingFinchSyncProps {
  setLoading: (loading: boolean) => void
  setOnboardingStep: (step: OnboardingStep) => void
}

const OnboardingFinchSync = React.memo((props: OnboardingFinchSyncProps) => {
  const { setLoading, setOnboardingStep } = props

  const { allFinchWorksites, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const dispatch = useDispatch()

  const [selectedWorksites, setSelectedWorksites] = useState<Set<string>>(
    new Set()
  )

  const formattedProviderName = useMemo(
    () => convertToTitleCase(profile?.payrollProviderId?.replace(/_/g, ' ')),
    [profile?.payrollProviderId]
  )

  const handleWorksiteRadioClick = (worksiteId: string) => {
    if (worksiteId === 'all') {
      const syncableFinchWorksites = allFinchWorksites.filter(
        (worksite) => worksite.canSync
      )
      if (selectedWorksites.size < syncableFinchWorksites.length) {
        setSelectedWorksites(
          new Set(syncableFinchWorksites.map((worksite) => worksite.id))
        )
      } else {
        setSelectedWorksites(new Set())
      }
    } else if (selectedWorksites.has(worksiteId)) {
      setSelectedWorksites((prev) => {
        prev.delete(worksiteId)
        return new Set(prev)
      })
    } else {
      setSelectedWorksites((prev) => new Set(prev.add(worksiteId)))
    }
  }

  const renderSyncableFinchWorksites = (allFinchWorksites: FinchWorksite[]) => {
    return allFinchWorksites
      .filter((worksite) => worksite.canSync)
      .map((worksite) => (
        <RadioV2
          key={worksite.id}
          active={selectedWorksites.has(worksite.id)}
          label={`${worksite.address?.split(',')[0]} (${
            worksite.newEmployees
          } employee${worksite.newEmployees === 1 ? '' : 's'})`}
          onClick={() => handleWorksiteRadioClick(worksite.id)}
          marginY='0.25rem'
        />
      ))
  }

  const handleIntegrateWorksites = () => {
    setLoading(true)
    employerService
      .bulkSyncFinchWorksiteEmployees(Array.from(selectedWorksites))
      .then((res) =>
        dispatch(
          employerActions.bulkSyncFinchWorksiteEmployeesSuccess(res.data)
        )
      )
      .then(() => dispatch(employeeActions.getCommuters()))
      .then(() => dispatch(employerActions.getWorksites()))
      .then(() => setLoading(false))
      .then(() => setOnboardingStep('addPayment'))
  }

  return (
    <Container>
      {/* Success message for finch connect */}
      <SuccessMsgContainer>
        <Text variant='action6' textAlign='center'>
          Congratulations! You have successfully connected with{' '}
          {formattedProviderName
            ? formattedProviderName
            : 'your payroll provider'}
        </Text>
      </SuccessMsgContainer>
      <ContentContainer>
        {/* If there are no Finch Worksites then we know that they are an assisted provider so we can move them to the next step */}
        <Text variant='action4'>
          {allFinchWorksites.length === 0
            ? `Due to syncing limitations of Finch and ${formattedProviderName}, your workforce data may take up to 7 days to sync. We will notify you when your workforce data is ready to be integrated. Stay tuned!`
            : 'Select the worksites you would like to integrate into Fleet:'}
        </Text>

        {allFinchWorksites.length > 0 ? (
          <RadioListContainer>
            <RadioV2
              key='all'
              active={selectedWorksites.size === allFinchWorksites.length}
              label={
                <Text variant='action4' color={palette.secondary.maroon1}>
                  Select All
                </Text>
              }
              onClick={() => handleWorksiteRadioClick('all')}
              marginY='0.25rem'
            />
            <Divider />
            <FinchWorksitesContainer>
              {renderSyncableFinchWorksites(allFinchWorksites)}
            </FinchWorksitesContainer>
            <Button
              onClick={() => handleIntegrateWorksites()}
              disabled={selectedWorksites.size === 0}
              marginTop='1rem'
            >
              Integrate Worksites
            </Button>
          </RadioListContainer>
        ) : (
          <Button
            onClick={() => setOnboardingStep('addPayment')}
            marginTop='1rem'
          >
            Add Payment Method
          </Button>
        )}
      </ContentContainer>
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingFinchSync.displayName = 'OnboardingFinchSync'
}

export default OnboardingFinchSync
