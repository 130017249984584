import OptionCard from 'core-system/Card/OptionCard/OptionCard'
import Chip from 'core-system/Chip'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import * as FlexIcons from 'core-system/Icons/Flex'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import Radio from 'core-system/Radio'
import { SwitchToggleV2 } from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import React, { useState, useEffect, ReactElement } from 'react'
import DateUtils from 'shared/DateUtils'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import InfoIcon from 'core-system/Icons/Misc/Info'
import DatePicker from 'core-system/DatePicker'
import QTFUtils from 'features/QTF/Shared/QTFUtils'
import ConnectHris from 'features/Segments/components/ConnectHris'
import FormattingUtils from 'shared/FormattingUtils'
import moment, { Moment } from 'moment'
import QTFWorksiteDateSelect from './QTFWorksiteDateSelect'
import { Worksite } from 'redux/employer/employerTypes'
import { AppState } from 'redux/config/store'
import { useSelector } from 'react-redux'
import pxToRem from 'core-system/utils/pxToRem'

const Container = styled.div<SpaceProps>`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
  color: black;
  ${space}
`

const isOnOrAfter27th = Number(moment().format('D')) >= 27

const StyledList = styled.ul`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1.4375rem;
`

const StyledListItem = styled.li`
  color: ${(props) => props.theme.palette.black};
  ${(props) => props.theme.typography.body1};
`

const StyledLink = styled.a`
  color: ${palette.grey.grey10};
  text-decoration: underline;

  &:hover {
    color: ${palette.primary.primaryPurple};
  }
`

const IconContainer = styled.div`
  min-width: 3rem;
  height: 3rem;
  background-color: ${(props) => props.theme.palette.secondary.maroon1};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ActivateContainer = styled.div`
  padding-top: ${pxToRem(64)};
`

const StaticContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.secondary.purple7};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  border-radius: 0.5rem;
  max-width: ${(props) => props.theme.pxToRem(460)};
`

const renderContributionLimitActivate = (
  type: 'Transit' | 'Parking',
  icon: React.ReactNode,
  canEdit: boolean,
  active: boolean,
  handleToggle: (type: 'parking' | 'transit') => void
) => {
  return (
    <FlexContainer
      alignItems='center'
      marginBottom='1rem'
      justifyContent='space-between'
    >
      <FlexContainer>
        <IconContainer>{icon}</IconContainer>
        <FlexContainer
          flexDirection='column'
          marginLeft='1rem'
          justifyContent='center'
        >
          <Text variant='h4' color={palette.black}>
            {type}{' '}
          </Text>
        </FlexContainer>
      </FlexContainer>
      {canEdit ? (
        <SwitchToggleV2
          active={active}
          onClick={() =>
            handleToggle(type.toLowerCase() as 'parking' | 'transit')
          }
        />
      ) : (
        <Chip variant={active ? 'green' : 'grey'}>
          {active ? 'Active' : 'Disabled'}
        </Chip>
      )}
    </FlexContainer>
  )
}

const renderEmployerContribution = (
  type: 'Transit' | 'Parking',
  icon: React.ReactNode,
  canEdit: boolean,
  contributionAmount: number,
  onContributionChange: (amount: number) => void,
  maxContribution: number,
  active: boolean
) => {
  return (
    <FlexContainer flexDirection='column' marginBottom='1rem'>
      <FlexContainer alignItems='flex-end'>
        <IconContainer>{icon}</IconContainer>
        <FlexContainer flexDirection='column' marginLeft='1rem' width={'100%'}>
          <Text variant='body1'>{type} Contribution</Text>
          {active && canEdit ? (
            <FlexContainer flexDirection='column'>
              <NumberInput
                padding='1rem'
                onValueChange={onContributionChange}
                value={contributionAmount / 100}
                iconSize={24}
                incrementVal={5}
                maxHeight='3rem'
              >
                <NumberFormatter decimalScale={0} fontVariant='action2' />
              </NumberInput>
            </FlexContainer>
          ) : (
            <Text
              variant='h3'
              textColor={active ? palette.text.primary : palette.text.disabled}
            >
              {active
                ? FormattingUtils.formatDollar(contributionAmount, 0)
                : `${type.toUpperCase()} NOT ACTIVE`}
            </Text>
          )}
        </FlexContainer>
      </FlexContainer>
      {canEdit &&
      active &&
      (maxContribution < contributionAmount || contributionAmount < 0) ? (
        <FlexContainer marginLeft='4rem' marginTop='0.25rem'>
          <Text variant='body2' textColor={palette.secondary.red1}>
            Please enter a value equal to or less than{' '}
            {FormattingUtils.formatDollar(maxContribution, 0)}
          </Text>
        </FlexContainer>
      ) : null}
    </FlexContainer>
  )
}

interface QTFActivationSetupProps extends SpaceProps {
  contribStartDate: Moment
  setupMode: 'manual' | 'hris'
  setSetupMode: (mode: 'manual' | 'hris') => void
  transitActive: boolean
  parkingActive: boolean
  employerTransitContribution: number
  employerParkingContribution: number
  onEmployerTransitContributionChange: (amount: number) => void
  onEmployerParkingContributionChange: (amount: number) => void
  handleToggle: (type: 'parking' | 'transit') => void
  contributionDays: number
  onContributionDayChange: (newDay: number) => void
  hrisProviderName: string | null
  hrisConnectReAuth: boolean
  hrisConfig: { closingDate: Date; payrollSchedule: string } | null
  setHrisConfig?: (config: {
    closingDate: Date
    payrollSchedule: string
  }) => void
  canEdit?: boolean
  activationView?: boolean
  initialSetupDate?: Date
  canUseAutomatedBenefits?: boolean
  isOnboarding?: boolean
  handleDateChange?: (val: string, text: string, initial?: boolean) => void
  setEstimatedMonthlySpend?: (num: number) => void
  setSelectedWorksiteSegments?: (worksite: string[]) => void
  allWorksites?: Worksite[]
  segmentId?: string
  activateButton?: ReactElement
}

const QTFActivationSetup = React.memo((props: QTFActivationSetupProps) => {
  const {
    contribStartDate,
    setupMode = 'manual',
    setSetupMode,
    transitActive,
    parkingActive,
    employerTransitContribution,
    employerParkingContribution,
    onEmployerTransitContributionChange,
    onEmployerParkingContributionChange,
    handleToggle,
    contributionDays,
    onContributionDayChange,
    canEdit = true,
    hrisConfig,
    setHrisConfig,
    activationView = false,
    initialSetupDate,
    hrisProviderName,
    hrisConnectReAuth,
    canUseAutomatedBenefits,
    isOnboarding = false,
    handleDateChange,
    setEstimatedMonthlySpend,
    setSelectedWorksiteSegments,
    allWorksites,
    segmentId,
    activateButton,
  } = props

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const discountResult = useSelector(
    (state: AppState) => state.employer.discount
  )

  const [numberOfEmployees, setNumberOfEmployees] = useState(0)
  const currentYear = moment().year()

  useEffect(() => {
    if (segmentId && Object.keys(segmentsMap).length !== 0) {
      setNumberOfEmployees(segmentsMap[segmentId].commuters)
    }
  }, [segmentId, segmentsMap, setNumberOfEmployees])

  useEffect(() => {
    setEstimatedMonthlySpend(
      numberOfEmployees *
        (employerTransitContribution + employerParkingContribution) *
        (discountResult?.couponDetails?.percentOff
          ? (100 - discountResult.couponDetails.percentOff) / 100
          : 1) -
        (discountResult?.couponDetails?.amountOff
          ? discountResult.couponDetails.amountOff
          : 0)
    )
  }, [
    numberOfEmployees,
    employerTransitContribution,
    employerParkingContribution,
    setEstimatedMonthlySpend,
    discountResult,
  ])

  const handleHrisConfig = (
    option: 'closingDate' | 'payrollSchedule',
    value: any
  ) => {
    setHrisConfig &&
      setHrisConfig({
        ...hrisConfig,
        [option]: value,
      })
  }

  const getCurrentMonthDeadlineDay = (contribDay: number) => {
    return moment(`${moment().format('YYYY-MM')}-${contribDay}`, 'YYYY-MM-DD')
  }

  return (
    <Container>
      {isOnboarding && (
        <>
          <FlexContainer alignItems='center' justifyContent='space-between'>
            <Text variant='h1' color='black'>
              Last Step: Launch!
            </Text>
          </FlexContainer>
          <Text variant='h4' color='black' margin='1.5rem 0'>
            You&apos;re just a few clicks away from offering amazing benefits to
            your employees.
          </Text>
          <Divider margin='1.5rem 0' />
        </>
      )}
      {!isOnboarding && (
        <>
          <FlexContainer alignItems='center' justifyContent='space-between'>
            <Text variant='h4'>Program Set Up</Text>
          </FlexContainer>
          <Text variant='body1' margin='1.5rem 0'>
            Please select how you’d like to process commuter allocations:
          </Text>
          <FlexContainer style={{ gap: '2rem' }}>
            <OptionCard
              flex='1'
              title='HRIS Integration with Fleet'
              active={setupMode === 'hris'}
              onClick={() => setSetupMode('hris')}
              canSelect={activationView && canUseAutomatedBenefits}
            >
              <StyledList>
                <StyledListItem>Faster processing time</StyledListItem>
                <StyledListItem>
                  Commuters can adjust their contributions up to 7-10 days
                  before end of payroll
                </StyledListItem>
                <StyledListItem>
                  Fleet automatically adjusts all commuter paychecks
                </StyledListItem>
                <StyledListItem>No additional work required</StyledListItem>
              </StyledList>
              {canUseAutomatedBenefits === false && (
                <Text variant='action4'>
                  Automated Benefits are not available for this worksite.
                </Text>
              )}
              {((activationView && canUseAutomatedBenefits) ||
                (!activationView && setupMode === 'hris')) && (
                <FlexContainer alignSelf={'center'} justifyContent='center'>
                  <ConnectHris
                    providerName={hrisProviderName}
                    reAuth={hrisConnectReAuth}
                    btnSize={
                      hrisProviderName && hrisConnectReAuth === false
                        ? 'large'
                        : 'small'
                    }
                    active={
                      hrisProviderName && hrisConnectReAuth === false
                        ? true
                        : false
                    }
                  />
                </FlexContainer>
              )}
            </OptionCard>
            <OptionCard
              flex='1'
              title='Manually Upload & Process Commuters Contributions'
              active={setupMode === 'manual'}
              onClick={() => setSetupMode('manual')}
              canSelect={activationView && !canUseAutomatedBenefits}
            >
              <StyledList>
                <StyledListItem>
                  Longer processing time and additional admin work required
                </StyledListItem>
                <StyledListItem>
                  Commuters input contributions into mobile app
                </StyledListItem>
                <StyledListItem>
                  Access CSV with commuter contribution information for manual
                  input into HRIS
                </StyledListItem>
                <StyledListItem>
                  Make payroll adjustments accordingly
                </StyledListItem>
              </StyledList>
            </OptionCard>
          </FlexContainer>
          <Divider margin='1.5rem 0' />
        </>
      )}
      {setupMode === 'hris' && (
        <>
          <Divider margin='1.5rem 0' />
          <Text variant='action2' margin='1.5rem 0'>
            Payroll Schedule
          </Text>
          <FlexContainer alignItems='center' justifyContent='space-between'>
            <FlexContainer alignItems='center' justifyContent='space-between'>
              <Radio
                id='radioContainer'
                active={hrisConfig.payrollSchedule === 'BI_WEEKLY'}
                onClick={() => handleHrisConfig('payrollSchedule', 'BI_WEEKLY')}
                disabled={!activationView}
              />
              <Text
                variant='body1'
                marginLeft={'0.5rem'}
                marginTop={'0.0625rem'}
              >
                {QTFUtils.payrollScheduleOptions['BI_WEEKLY']}
              </Text>
            </FlexContainer>
            <FlexContainer alignItems='center' justifyContent='space-between'>
              <Radio
                id='radioContainer'
                active={hrisConfig.payrollSchedule === 'BI_MONTHLY'}
                onClick={() =>
                  handleHrisConfig('payrollSchedule', 'BI_MONTHLY')
                }
                disabled={!activationView}
              />
              <Text
                variant='body1'
                marginLeft={'0.5rem'}
                marginTop={'0.0625rem'}
              >
                {QTFUtils.payrollScheduleOptions['BI_MONTHLY']}
              </Text>
            </FlexContainer>
            <FlexContainer alignItems='center' justifyContent='space-between'>
              <Radio
                id='radioContainer'
                active={hrisConfig.payrollSchedule === 'MONTHLY'}
                onClick={() => handleHrisConfig('payrollSchedule', 'MONTHLY')}
                disabled={!activationView}
              />
              <Text
                variant='body1'
                marginLeft={'0.5rem'}
                marginTop={'0.0625rem'}
              >
                {QTFUtils.payrollScheduleOptions['MONTHLY']}
              </Text>
            </FlexContainer>
          </FlexContainer>
          {activationView && (
            <>
              <FlexContainer
                alignItems='center'
                justifyContent='space-between'
                marginTop={'1.5rem'}
                marginBottom={'1rem'}
              >
                <Text variant='action2'>Next Closing Date</Text>
                <SvgTooltip
                  svg={
                    <InfoIcon
                      width={20}
                      height={20}
                      color={palette.text.secondary}
                    />
                  }
                  height='1.25rem'
                  isMultiLine={true}
                  description={
                    'Please select the last day changes can be made to payroll from the date you would like to start the program.'
                  }
                />
              </FlexContainer>
              <DatePicker
                id='date-picker'
                onChange={(selectedDate: Date) =>
                  handleHrisConfig('closingDate', selectedDate)
                }
                selected={hrisConfig.closingDate}
                shouldCloseOnSelect={true}
                minDate={initialSetupDate || new Date()}
                dateFormat='MMMM d, yyyy'
                fixedHeight
                required={true}
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    escapeWithReference: true,
                  },
                }}
              />
            </>
          )}
          <Divider margin='1.5rem 0' />
        </>
      )}
      <Text variant='h4' color={palette.black}>
        Which benefits would you like to offer?
      </Text>
      <FlexContainer flexDirection='column' marginTop='1rem'>
        {renderContributionLimitActivate(
          'Transit',
          <FlexIcons.Transit width={32} height={32} color={palette.white} />,
          canEdit,
          transitActive,
          handleToggle
        )}
        {renderContributionLimitActivate(
          'Parking',
          <FlexIcons.Parking width={32} height={32} color={palette.white} />,
          canEdit,
          parkingActive,
          handleToggle
        )}
      </FlexContainer>
      <Divider margin='1.5rem 0' />
      <Text variant='h4' color={palette.black}>
        How much will you, as an employer, contribute to each program?
      </Text>
      <Text variant='body1' margin='1.5rem 0' color={palette.grey.grey10}>
        <StyledLink
          href={`https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-${currentYear}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          According to the IRS
        </StyledLink>
        , as an employer, you may contribute up to{' '}
        {FormattingUtils.formatDollar(QTFUtils.monthlyPreTaxParking, 0) + ' '}
        for Parking and up to{' '}
        {FormattingUtils.formatDollar(QTFUtils.monthlyPreTaxTransit, 0)} for
        Transit per month.
      </Text>
      {renderEmployerContribution(
        'Transit',
        <FlexIcons.Transit width={32} height={32} color={palette.white} />,
        canEdit,
        employerTransitContribution,
        onEmployerTransitContributionChange,
        QTFUtils.monthlyPreTaxTransit,
        transitActive
      )}
      {renderEmployerContribution(
        'Parking',
        <FlexIcons.Parking width={32} height={32} color={palette.white} />,
        canEdit,
        employerParkingContribution,
        onEmployerParkingContributionChange,
        QTFUtils.monthlyPreTaxParking,
        parkingActive
      )}
      {!segmentId && allWorksites && (
        <>
          <Divider margin='1.5rem 0' />
          <QTFWorksiteDateSelect
            onDateChange={handleDateChange}
            showDates2MonthsLater={isOnOrAfter27th}
            setNumberOfEmployees={setNumberOfEmployees}
            setSelectedWorksiteSegments={setSelectedWorksiteSegments}
            allWorksites={allWorksites}
          />
        </>
      )}
      <Divider margin='1.5rem 0' />
      <FlexContainer alignItems='center' justifyContent='space-between'>
        <Text variant='h4' color={palette.black}>
          When do you want to set the deadline for your employees to allocate
          for the following month?
        </Text>
      </FlexContainer>
      {/* <Text variant='body1' margin='1.5rem 0'>
        You can use this to set the latest date employees can declare
        allocation. The latest date that we allow is the 27th of the month.
      </Text> */}
      {setupMode === 'manual' ? (
        <FlexContainer flexDirection='column' marginBottom='1rem'>
          <Text variant='h4' marginBottom='1rem'>
            By what date should your employees finalize their allocations for
            the following month?
          </Text>
          <Text variant='body1'>
            You can use this to set the latest date employees can declare
            allocation. The latest date that we allow is the 27th of the month.
          </Text>
        </FlexContainer>
      ) : (
        <Text variant='h4' marginBottom='1rem'>
          The latest date your employees can finalize their allocations for the
          following month:
        </Text>
      )}

      {canEdit && setupMode === 'manual' ? (
        <>
          <NumberInput
            padding='1rem'
            onValueChange={onContributionDayChange}
            value={contributionDays}
            iconSize={24}
            incrementVal={1}
          >
            <NumberFormatter
              currencyFormat='days'
              decimalScale={0}
              fontVariant='h3'
            />
          </NumberInput>
          {moment().isAfter(
            moment(
              `${contribStartDate.format('YYYY-MM')}-${contributionDays}`,
              'YYYY-MM-DD'
            )
          ) ? (
            <Text
              variant='body2'
              marginTop='0.5rem'
              textColor={palette.secondary.red1}
            >
              {`Please note that since this allocation deadline is on or before the current date, you must set the spend start date to ${getCurrentMonthDeadlineDay(
                contributionDays
              )
                .add(2, 'months')
                .format('MMMM')} at the earliest.`}
            </Text>
          ) : null}
        </>
      ) : (
        <StaticContainer>
          <Text variant='h1'>
            {DateUtils.getDayOfTheMonth(contributionDays)} of every month
          </Text>
        </StaticContainer>
      )}
      <ActivateContainer>{activateButton}</ActivateContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFActivationSetup.displayName = 'QTFActivationSetup'
}

export default QTFActivationSetup
