import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import moment from 'moment'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

const PinkContainer = styled.div<SpaceProps>`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.maroon3};

  ${space}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

const CaptionText = styled.div`
  ${(props) => props.theme.typography.action3};
  color: ${(props) => props.theme.palette.secondary.maroon1};
  text-transform: uppercase;
  letter-spacing: 0.7px;
  white-space: nowrap;
  font-family: 'PolySans';
`

const EndDateContainer = styled.div`
  margin-top: 1rem;
`

const Spacer = styled.div`
  margin-top: 1rem;
`

interface SummaryOverviewProps
  extends SpaceProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  currentSegment: Segment
  startDate: string
  endDate?: string
  qtf?: boolean
  children?: React.ReactNode
}

const SummaryOverview = React.memo((props: SummaryOverviewProps) => {
  const { currentSegment, startDate, endDate, qtf, children, ...rest } = props
  return (
    <PinkContainer {...rest}>
      <HeaderContainer>
        {/* <PlatformSvg folder='metrics' variant='graphs' /> */}
        <Text variant='h4' color={palette.black}>
          Overview
        </Text>
      </HeaderContainer>
      {currentSegment && (
        <>
          <CaptionText>Group</CaptionText>
          <Text variant='action4' margin='0.25rem 0 1rem' color={palette.black}>
            {currentSegment.name} ({currentSegment.commuters} Commuters)
          </Text>
        </>
      )}
      {qtf ? (
        <>
          <CaptionText>Allocation Start Date</CaptionText>
          <Text variant='action4' marginTop='0.25rem'>
            {startDate}
          </Text>
          <Spacer />
          <CaptionText>Spend Start Date</CaptionText>
          <Text variant='action4' marginTop='0.25rem' color={palette.black}>
            {moment(startDate, 'MMMM Do YYYY')
              .add(1, 'M')
              .format('MMMM D, YYYY')}
          </Text>
        </>
      ) : (
        <>
          <CaptionText>Start Date</CaptionText>
          <Text variant='action4' marginTop='0.25rem' color={palette.black}>
            {startDate}
          </Text>
        </>
      )}
      {endDate && (
        <EndDateContainer>
          <CaptionText>End Date</CaptionText>
          <Text variant='action4' marginTop='0.25rem' color={palette.black}>
            {endDate}
          </Text>
        </EndDateContainer>
      )}
      {children && children}
    </PinkContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SummaryOverview.displayName = 'SummaryOverview'
}

export default SummaryOverview
