import React, { useCallback, useMemo, useState } from 'react'
import { QtfPerEmployeeAllocation } from 'redux/qtf/qtfTypes'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import Button from 'core-system/Button'
import DownloadIcon from 'core-system/Icons/Actions/Download'
import palette from 'core-system/Themes/palette'
import { useDispatch } from 'react-redux'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Loading from 'core-system/Loading'
import pxToRem from 'core-system/utils/pxToRem'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import FormattingUtils from 'shared/FormattingUtils'
import moment from 'moment'
import { ProgramModal } from 'core-system/Program'
import { qtfActions } from 'redux/qtf/qtfSlice'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import SegmentService from 'redux/config/services/SegmentService'
import EditEmployeeAllocationModal from 'features/QTF/Activation/components/EditEmployeeAllocationModal'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-top: 1rem;
`
const Section = styled.div`
  width: 100%;
  padding: 1rem;
  margin: 1.5rem 0;
  border: 1px solid ${palette.grey.grey3};
  border-radius: 0.9375rem;
`
const EmptyTableRow = styled(TableRow)`
  text-align: center;
`
const UserContainer = styled.div`
  display: flex;
`
const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const getTableRows = (
  allocations: Dictionary<any>,
  menuCallback: (item: SelectItemProps) => void
) => {
  return Object.keys(allocations)
    .sort(
      (a, b) =>
        (!allocations[b]?.deductions?.find(
          (deduction) => deduction.type === 'COMMUTER_TRANSIT'
        )
          ? -1
          : allocations[b].deductions.find(
              (deduction) => deduction.type === 'COMMUTER_TRANSIT'
            ).employeeDeduction || 0) -
          (!allocations[a]?.deductions?.find(
            (deduction) => deduction.type === 'COMMUTER_TRANSIT'
          )
            ? -1
            : allocations[a].deductions.find(
                (deduction) => deduction.type === 'COMMUTER_TRANSIT'
              ).employeeDeduction || 0) ||
        (!allocations[b]?.deductions?.find(
          (deduction) => deduction.type === 'COMMUTER_PARKING'
        )
          ? -1
          : allocations[b].deductions.find(
              (deduction) => deduction.type === 'COMMUTER_PARKING'
            ).employeeDeduction || 0) -
          (!allocations[a]?.deductions?.find(
            (deduction) => deduction.type === 'COMMUTER_PARKING'
          )
            ? -1
            : allocations[a].deductions.find(
                (deduction) => deduction.type === 'COMMUTER_PARKING'
              ).employeeDeduction || 0)
    )
    .map((employeeId, idx: number) => {
      const selectItems = [
        {
          id: '0',
          text: 'Edit Upcoming Allocation',
          allocation: allocations[employeeId],
        },
      ]

      return (
        <TableRow key={idx} display='flex'>
          <TableRowContents isClickable={false} autoResize={false}>
            <TableRowCell width='25%' equalSize={false}>
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {allocations[employeeId].name}
                  </Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {allocations[employeeId].employees_employeeCorporateId
                      ? `#${allocations[employeeId].employees_employeeCorporateId}`
                      : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell width='15%' equalSize={false}>
              <Text variant='action4'>
                {allocations[employeeId]?.deductions?.find(
                  (deduction) => deduction.type === 'COMMUTER_TRANSIT'
                )?.employeeDeduction
                  ? FormattingUtils.formatDollar(
                      allocations[employeeId].deductions.find(
                        (deduction) => deduction.type === 'COMMUTER_TRANSIT'
                      ).employeeDeduction,
                      2
                    )
                  : '--'}
              </Text>
            </TableRowCell>
            <TableRowCell width='15%' equalSize={false}>
              <Text variant='action4'>
                {allocations[employeeId]?.deductions?.find(
                  (deduction) => deduction.type === 'COMMUTER_PARKING'
                )?.employeeDeduction
                  ? FormattingUtils.formatDollar(
                      allocations[employeeId].deductions.find(
                        (deduction) => deduction.type === 'COMMUTER_PARKING'
                      ).employeeDeduction,
                      2
                    )
                  : '--'}
              </Text>
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false}>
              <Text variant='action4'>
                {allocations[employeeId].employees_email || '-'}
              </Text>
            </TableRowCell>
            <TableRowCell width='5%' equalSize={false}>
              <Dropdown
                variant='textual'
                svgDropdown={true}
                icon={<OverflowIcon />}
                active={null}
                items={selectItems}
                itemCallback={menuCallback}
                width={pxToRem(210)}
                top='2.75rem'
                right={0}
                data-cy='upcoming-allocations-table-dropdown'
              />
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const getFormattedDeadlineDay = (day: number) => {
  const today = moment()
  // months are 0 indexed
  return moment(
    `${today.year()}-${today.month() + 1}-${day}`,
    'YYYY-MM-DD'
  ).format('MMMM Do, YYYY')
}

interface QTFAllocationProps {
  allocations: Dictionary<any>
  totalEmployees: number
  deadlineDay: number
  openReminderModal: boolean | null
  segmentId: string
  segmentName: string
}
const QTFAllocations = React.memo((props: QTFAllocationProps) => {
  const {
    allocations,
    totalEmployees,
    deadlineDay,
    openReminderModal,
    segmentId,
    segmentName,
  } = props
  const dispatch = useDispatch()

  const displayMonthYear = moment().add(1, 'M').format('MMMM YYYY')

  const [isModalOpen, setIsModalOpen] = useState(openReminderModal)
  const [selectedEmployeeAllocation, setSelectedEmployeeAllocation] =
    useState<QtfPerEmployeeAllocation>(null)
  const [editAllocationModalOpen, setEditAllocationModalOpen] = useState(false)

  const participatingEmps = useMemo(() => {
    if (!allocations) return 0
    return Object.keys(allocations).reduce((total, curr) => {
      if (
        allocations[curr]?.deductions?.find(
          (deduction) => deduction.type === 'COMMUTER_TRANSIT'
        )?.employeeDeduction ||
        allocations[curr]?.deductions?.find(
          (deduction) => deduction.type === 'COMMUTER_PARKING'
        )?.employeeDeduction
      )
        total++
      return total
    }, 0)
  }, [allocations])

  const handleSendReminder = useCallback(() => {
    dispatch(qtfActions.sendEmployeeReminder({ segmentId }))
    setIsModalOpen(false)
  }, [segmentId, dispatch])

  const handleDownloadBtn = useCallback(() => {
    dispatch(
      qtfActions.getQTFDashboardDeductions({
        segmentId,
        format: 'csv',
        segmentName,
      })
    )
  }, [segmentId, dispatch, segmentName])

  const handleModalToggle = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen])

  const closeEditEmployeeAllocationModal = useCallback(() => {
    setEditAllocationModalOpen(false)
    SegmentService.track('table-item-click', {
      tableType: 'upcoming-allocations',
      action: 'edit-allocation-modal-close',
      employeeName: selectedEmployeeAllocation.name,
    })
    setSelectedEmployeeAllocation(null)
  }, [
    setEditAllocationModalOpen,
    selectedEmployeeAllocation,
    setSelectedEmployeeAllocation,
  ])

  const menuCallback = (item: SelectItemProps) => {
    setSelectedEmployeeAllocation(item.allocation)
    if (item.id === '0') {
      setEditAllocationModalOpen(true)
      SegmentService.track('table-item-click', {
        tableType: 'upcoming-allocations',
        action: 'open',
        employeeName: item.allocation && item.allocation.name,
      })
    }
  }

  if (!allocations) return <Loading height={pxToRem(500)} marginTop={'1rem'} />

  return (
    <>
      <Container>
        <FlexContainer centerApart={true}>
          <div>
            <Text variant='h5'>Upcoming Commuter Allocations</Text>
            <Text
              variant='action4'
              fontWeight={600}
              textColor={palette.text.secondary}
              captionTitle={true}
            >
              For {displayMonthYear}
            </Text>
          </div>
          <Button
            size='small'
            iconLeft={<DownloadIcon height={18} width={18} />}
            onClick={handleDownloadBtn}
          >
            Download Allocation Report
          </Button>
        </FlexContainer>
        <Text variant='body1' marginTop={'1.5rem'}>
          Allocation Deadline: {getFormattedDeadlineDay(deadlineDay)}
        </Text>
        <Section>
          <FlexContainer centerApart={true}>
            <div>
              <Text
                variant='caption'
                textColor={palette.text.secondary}
                captionTitle={true}
              >
                Participating Commuters
              </Text>
              <FlexContainer marginTop={'0.5rem'}>
                <Text variant='body1' fontWeight={800}>
                  {participatingEmps}
                </Text>
                <Text variant='body1'>
                  &nbsp;out of {totalEmployees} commuters have set their
                  allocations for the upcoming month.
                </Text>
              </FlexContainer>
            </div>
            {participatingEmps !== totalEmployees && (
              <Button
                variant='secondary'
                size='small'
                onClick={handleModalToggle}
              >
                Send Reminder
              </Button>
            )}
          </FlexContainer>
        </Section>
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
          <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
            <TableRowCell width='25%' equalSize={false}>
              Commuter
            </TableRowCell>
            <TableRowCell width='15%' equalSize={false}>
              Transit Amount
            </TableRowCell>
            <TableRowCell width='15%' equalSize={false}>
              Parking Amount
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false}>
              Email
            </TableRowCell>
          </TableLabelRow>
          {allocations && Object.keys(allocations).length > 0 ? (
            getTableRows(allocations, menuCallback)
          ) : (
            <EmptyTableRow>No Allocations</EmptyTableRow>
          )}
        </Table>
      </Container>
      <ProgramModal
        title='Send Reminder'
        description={`${
          totalEmployees - participatingEmps
        } have not set their allocations for the next month. Would you like to send them a reminder?`}
        buttonText='Send'
        open={isModalOpen}
        closeModal={handleModalToggle}
        buttonCallback={handleSendReminder}
      />
      {selectedEmployeeAllocation ? (
        <EditEmployeeAllocationModal
          allocation={selectedEmployeeAllocation}
          closeModal={closeEditEmployeeAllocationModal}
          open={editAllocationModalOpen}
        />
      ) : null}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFAllocations.displayName = 'QTFAllocations'
}

export default QTFAllocations
