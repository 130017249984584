import Banner from 'core-system/Banner'
import Chip, { statusMap } from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import { QtfProgram, QtfProgramGroup } from 'redux/qtf/qtfTypes'
import { Locations } from 'shared/Router/Locations'
import StatusUtils from 'shared/StatusUtils'
import styled from 'styled-components'

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple7};
  }
`

const StatContainer = styled.div`
  margin-left: 2.4375rem;
`

const getActiveRow = (programGroup: QtfProgramGroup) => {
  //check if active program is cancelling
  if (programGroup.active && programGroup?.nextMonth?.status === 'CANCELLING') {
    return programGroup.nextMonth
  } else if (programGroup.active) {
    return programGroup.active
  } else if (programGroup.nextMonth) {
    return programGroup.nextMonth
  } else {
    return null
  }
}

const getProgramStatus = (program: QtfProgram) => {
  if (program?.typeMap) {
    const allStatuses = Object.keys(program.typeMap).reduce(
      (statuses, benefitType) => {
        statuses.add(program.typeMap[benefitType].status)
        return statuses
      },
      new Set()
    )
    if (allStatuses.has('ACTIVE')) {
      return 'ACTIVE'
    } else if (allStatuses.has('PAUSED')) {
      return 'PAUSED'
    } else if (allStatuses.has('UPDATING')) {
      return 'UPDATING'
    } else if (allStatuses.has('PENDING')) {
      return 'PENDING'
    } else if (allStatuses.has('CANCELLING')) {
      return 'CANCELLING'
    } else if (allStatuses.has('CANCELLED')) {
      return 'CANCELLED'
    } else {
      return 'DISABLED'
    }
  }
  return 'DISABLED'
}

const renderProgramRows = (
  worksites: Worksite[],
  qtfPrograms: Dictionary<any>,
  handleOnClick: (segmentId: string) => void
) => {
  return worksites.map((w) => {
    const isActive = qtfPrograms[w.segmentId]
    const activeRow = isActive && getActiveRow(qtfPrograms[w.segmentId])
    const activeRowStatus = getProgramStatus(activeRow)

    return (
      <ProgramRow key={w.id} onClick={() => handleOnClick(w.segmentId)}>
        <FlexContainer alignItems='center'>
          <PlatformSvg folder='offices' variant='office1' width={40} />
          <Text variant='action2' marginLeft='1rem'>
            {w.alias}
          </Text>
          <FlexContainer alignItems='center' marginLeft='auto'>
            <Chip
              variant={
                isActive ? statusMap[activeRowStatus].variant : 'disabled'
              }
            >
              {isActive ? statusMap[activeRowStatus].text : 'off'}
            </Chip>
            <StatContainer>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Commuters
              </Text>
              <Text variant='action2'>{w.employeeCount}</Text>
            </StatContainer>
          </FlexContainer>
        </FlexContainer>
      </ProgramRow>
    )
  })
}

const QTFPrograms = React.memo(() => {
  const navigate = useNavigate()

  const qtfPrograms = useSelector((state: AppState) => state.qtf.qtfPrograms)
  const worksites = useSelector((state: AppState) => state.employer.worksites)
  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`${segmentId}`, {
        state: { from: Locations.QTF.Programs },
      })
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'flex',
      })
    },
    [navigate, segmentsMap]
  )

  if (!qtfPrograms || !worksites) {
    return <Loading fullPage />
  }

  const orderedWorksites = [...worksites]
    .filter((w) => w.archived === false)
    .sort((a, b) => {
      const firstSegmentVal = qtfPrograms[a.id]
        ? StatusUtils.statusOrder[getActiveRow(qtfPrograms[a.id]).status]
        : 6
      const secondSegmentVal = qtfPrograms[b.id]
        ? StatusUtils.statusOrder[getActiveRow(qtfPrograms[b.id]).status]
        : 6
      return firstSegmentVal - secondSegmentVal
    })

  return (
    <>
      {Object.keys(qtfPrograms).length === 0 ? (
        <>
          <Banner
            variant='cta'
            title='Offer Pre-Tax Commuter Benefits to your Commuters in 2 Clicks!'
            description='Save up to $550 per commuter while they save up to $3,000 per year!'
            background='bannerGeneral3'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your Pre-Tax Commuter Programs'
          description='Save up to $550 per commuter while they save up to $3,000 per year!'
          background='bannerGeneral3'
        />
      )}
      <div>
        {renderProgramRows(orderedWorksites, qtfPrograms, handleOnClick)}
      </div>
      <Outlet />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFPrograms.displayName = 'QTFPrograms'
}

export default QTFPrograms
