import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { employeeActions } from 'redux/employee/employeeSlice'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { employerActions } from 'redux/employer/employerSlice'
import { flexActions } from 'redux/flex/flexSlice'
import { grhActions } from 'redux/grh/grhSlice'
import { incentivesActions } from 'redux/incentives/incentivesSlice'
import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import { Notification as NotificationType } from 'redux/notifications/notificationTypes'
import { optInActions } from 'redux/optin/optInSlice'
import { otpActions } from 'redux/otp/otpSlice'
import { qtfActions } from 'redux/qtf/qtfSlice'
import { userActions } from 'redux/user/userSlice'
import { Action } from 'shared/Types/Actions'

export interface NotificationState {
  notifications: NotificationType[]
}

const initialState: NotificationState = {
  notifications: [],
}

const generateNotificationData = (
  title: string,
  subtitle: string,
  type?: 'success' | 'info' | 'alert'
): NotificationType => {
  return {
    key: new Date().getTime() + Math.random(),
    message: {
      title,
      subtitle,
      type,
    },
  }
}

const addNotification = (
  state: NotificationState,
  data: NotificationType
): NotificationState => {
  return {
    ...state,
    notifications: [...state.notifications, { ...data }],
  }
}

interface FailedAction extends Action {}

function isFailedAction(action: AnyAction): action is FailedAction {
  return action.type.endsWith('Failed')
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /* -- NOTIFICATIONS -- */
    enqueueSnackbar(state, action: PayloadAction<NotificationType>) {
      return addNotification(state, action.payload)
    },
    removeSnackbar(state, action: PayloadAction<number>) {
      state.notifications = state.notifications.filter(
        (n) => n.key !== action.payload
      )
    },
    generalPageError(state, action: PayloadAction<string>) {
      return addNotification(
        state,
        generateNotificationData('Page Error', action.payload, 'alert')
      )
    },
    generalPageInfo(
      state,
      action: PayloadAction<{ title: string; msg: string }>
    ) {
      return addNotification(
        state,
        generateNotificationData(
          action.payload.title,
          action.payload.msg,
          'success'
        )
      )
    },
  },
  extraReducers: (builder) => {
    builder
      /* -- CONCIERGE -- */
      .addCase(conciergeActions.createAnnouncementSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Announcement Created',
            'Your announcement has been saved and scheduled to be sent.',
            'success'
          )
        )
      })
      .addCase(conciergeActions.updateAnnouncementSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Announcement Updated',
            'Your announcement has been successfully updated.',
            'success'
          )
        )
      })
      .addCase(conciergeActions.deleteAnnouncementSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Announcement Archived',
            'Your announcement has been archived.',
            'success'
          )
        )
      })
      .addCase(conciergeActions.updateFaqSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'FAQS Updated',
            'Your FAQs have been successfully updated.',
            'success'
          )
        )
      })
      /* -- EMPLOYEE -- */
      .addCase(employeeActions.inviteEmployeesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Invitations Sent',
            'Your invitations have successfully been sent'
          )
        )
      })
      .addCase(employeeActions.resendEmployeeInviteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Re-invitation Sent',
            'Your re-invitation has successfully been sent'
          )
        )
      })
      .addCase(employeeActions.createEmployeeSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Invitation Sent',
            'Your invitation has successfully been sent'
          )
        )
      })
      .addCase(employeeActions.archiveEmployeeSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Employee Archived',
            'You have successfully archived your employee. All historic data will be preserved for reporting purposes!'
          )
        )
      })
      .addCase(employeeActions.reactivateEmployeeSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Employee Reactivated',
            'You have successfully reactivated your employee. They will be added back to relevant programs & worksites!'
          )
        )
      })
      .addCase(employeeActions.changeEmployeeWorksiteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Worksite Change Successful',
            `You have successfully changed an employee's worksite. They will be added to their new worksite's relevant programs!`
          )
        )
      })
      /* -- EMPLOYER -- */
      .addCase(employerActions.sendPlaidLinkTokenSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Bank Account Connected',
            'Your bank account has been successfully connected.',
            'success'
          )
        )
      })
      .addCase(employerActions.createWorksiteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Worksite Created',
            'Your Worksite has successfully been added to your account'
          )
        )
      })
      .addCase(employerActions.createSegmentSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Group Created',
            'Your new group has successfully been created'
          )
        )
      })
      .addCase(employerActions.updateSegmentSuccess, (state, action: any) => {
        const isCardTypeUpdate = action.meta.shippingDetails && true
        return addNotification(
          state,
          generateNotificationData(
            isCardTypeUpdate ? 'Card Preference Updated' : 'Group Updated',
            isCardTypeUpdate
              ? 'Your card type preference has successfully updated'
              : 'Your group has successfully been updated'
          )
        )
      })
      .addCase(employerActions.deleteSegmentSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Group Archived',
            'Your group has successfully been archived. All associated data is preserved for reporting!'
          )
        )
      })
      .addCase(employerActions.addSegmentEmployeesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Commuters Added',
            'Your group has successfully been updated'
          )
        )
      })
      .addCase(employerActions.removeSegmentEmployeesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Commuters Removed',
            'Your group has successfully been updated'
          )
        )
      })
      .addCase(
        employerActions.activateSegmentUpcomingAdditionsSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Commuters Activated',
              'Your group has successfully been updated.'
            )
          )
        }
      )
      .addCase(
        employerActions.removeSegmentUpcomingRemovalsSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Commuters Removed',
              'Your group has successfully been updated'
            )
          )
        }
      )
      .addCase(
        employerActions.forceActivateInactiveEmployeesSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Commuters Activated',
              'Your group has successfully been updated. Please refresh the page to see changes.'
            )
          )
        }
      )
      .addCase(employerActions.updateWorksiteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Worksite Settings Updated',
            'Your worksite preferences have been updated'
          )
        )
      })
      .addCase(employerActions.removePlaidLinkSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Bank Account Removed',
            'You have successfully removed your bank account connection. Connect a new payment method to keep your programs running!'
          )
        )
      })
      .addCase(employerActions.removePaymentMethodSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Payment Method Removed',
            'You have successfully removed your payment method. Connect a new payment method to keep your programs running!'
          )
        )
      })
      .addCase(employerActions.archiveWorksiteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Worksite Archived',
            'You have successfully archived your worksite and all associated commuters. All historic data will be preserved for reporting purposes!'
          )
        )
      })
      .addCase(employerActions.unarchiveWorksiteSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Worksite Unarchived',
            'You have successfully restored your worksite and all associated commuters.'
          )
        )
      })
      .addCase(employerActions.declineInactiveEmployeesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Commuters Declined',
            'You have successfully declined commuters. These changes could take a minute to take effect.',
            'success'
          )
        )
      })
      .addCase(employerActions.integrateHrisSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Payroll Provider Connected',
            'You have successfully connected your payroll provider.',
            'success'
          )
        )
      })
      .addCase(employerActions.disconnectHrisSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'HRIS Disconnected',
            'You have successfully disconnected your HRIS integration.'
          )
        )
      })
      .addCase(employerActions.updatePrimaryAcctHolder, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Account Owner Changed',
            'You have successfully changed the account owner.'
          )
        )
      })
      .addCase(employerActions.rolloutUsersSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Invites Sent!',
            'You have successfully sent email invites to the selected commuters.'
          )
        )
      })
      /* -- FLEX -- */
      .addCase(flexActions.createFlexProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Flex Program Created',
            'Your Flex program will begin at the scheduled start date'
          )
        )
      })
      .addCase(flexActions.updateFlexProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Flex Program Updated',
            'Your Flex Program has successfully updated'
          )
        )
      })
      .addCase(flexActions.cancelFlexProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Flex Program Cancelled',
            'Your Flex Program has successfully been cancelled. Reactivate anytime!'
          )
        )
      })
      /* -- INCENTIVES -- */
      .addCase(incentivesActions.createIncentivesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Incentives Created',
            'Your Incentives have been successfully created.',
            'success'
          )
        )
      })
      .addCase(incentivesActions.updateIncentivesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Incentives Updated',
            'Your Incentives have been successfully updated.',
            'success'
          )
        )
      })
      .addCase(incentivesActions.pauseIncentivesSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Incentives Cancelled',
            'Your Incentives have been Cancelled. Reactivate anytime!',
            'success'
          )
        )
      })
      .addCase(incentivesActions.createOneTimeRewardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Successfully Awarded One Time Reward',
            'The selected employee has successfully been awarded a One Time Reward!',
            'success'
          )
        )
      })
      .addCase(incentivesActions.bulkCreateOneTimeRewardsSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Successfully Awarded One Time Rewards',
            'The One Time Rewards have been successfully created!'
          )
        )
      })
      /* -- LEADERBOARDS -- */
      .addCase(leaderboardsActions.createLeaderboardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Leaderboard Activated',
            'Your Leaderboard will begin at the scheduled start date'
          )
        )
      })
      .addCase(leaderboardsActions.updateLeaderboardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Leaderboard Updated',
            'Your Leaderboard has successfully updated'
          )
        )
      })
      /* -- MICROMOBILITY -- */
      .addCase(
        micromobilityActions.createMicromobilityProgramSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Micromobility Program Created',
              'Your Micromobility program will begin at the scheduled start date'
            )
          )
        }
      )
      .addCase(
        micromobilityActions.updateMicromobilityProgramSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Micromobility Program Updated',
              'Your Micromobility Program has successfully updated'
            )
          )
        }
      )
      .addCase(
        micromobilityActions.cancelMicromobilityProgramSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Micromobility Program Cancelled',
              'Your Micromobility Program has successfully been cancelled. Reactivate anytime!'
            )
          )
        }
      )
      /* -- Qtf -- */
      .addCase(qtfActions.sendEmployeeReminderSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Notification Sent',
            'Notifications have been sent to your commuters'
          )
        )
      })
      .addCase(qtfActions.createQtfProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Program Created',
            'Your Pre-Tax Commuter program has been successfully created'
          )
        )
      })
      .addCase(qtfActions.updateQtfProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Program Updated',
            'Your Pre-Tax Commuter program has been successfully updated'
          )
        )
      })
      /* -- GRH -- */
      .addCase(grhActions.createGrhProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'GRH Program Created',
            'Your GRH program will begin at the scheduled start date'
          )
        )
      })
      .addCase(grhActions.updateGrhProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'GRH Program Updated',
            'Your GRH Program has successfully updated. Changes will come into effect next year.'
          )
        )
      })
      .addCase(grhActions.cancelGrhProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'GRH Program Cancelled',
            'Your GRH Program has successfully been cancelled. Reactivate anytime!'
          )
        )
      })

      /* -- OPT-IN -- */
      .addCase(optInActions.createOptInSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Opt-In Created',
            'Your Opt-In has been successfully created'
          )
        )
      })
      .addCase(optInActions.updateEmployeeOptInsSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Employee Choices Updated',
            'The Employee Opt-In Choices have been successfully updated'
          )
        )
      })

      /* -- OTP -- */
      .addCase(otpActions.createOtpProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'OTP Program Created',
            'Your OTP program will begin at the scheduled start date'
          )
        )
      })
      .addCase(otpActions.updateOtpProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'OTP Program Updated',
            'Your OTP Program has successfully updated. Changes will come into effect next year'
          )
        )
      })
      .addCase(otpActions.cancelOtpProgramSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'OTP Program Cancelled',
            'Your OTP Program has successfully been cancelled. Reactivate anytime!'
          )
        )
      })
      /* -- USER -- */
      .addCase(userActions.resetPasswordSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Reset Password Email Sent',
            'Please check your email. This can take a minute.'
          )
        )
      })

      /* -- EMPLOYEE PLATFORM PAYMENT METHODS -- */
      .addCase(employeePlatformActions.deletePaymentMethodSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Payment Method Deleted',
            'You have successfully deleted your payment method.'
          )
        )
      })

      /* -- EMPLOYEE PLATFORM PROGRAMS -- */
      .addCase(employeePlatformActions.createProgramRequestSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Request Created',
            'You have successfully created a request.'
          )
        )
      })
      .addCase(employeePlatformActions.cancelProgramRequestSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Request Cancelled',
            'You have successfully cancelled your request.'
          )
        )
      })

      /* -- EMPLOYEE PLATFORM CARDS -- */
      .addCase(employeePlatformActions.lockCardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Card Locked',
            'You have successfully locked your card.'
          )
        )
      })
      .addCase(employeePlatformActions.unlockCardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Card Unlocked',
            'You have successfully unlocked your card.'
          )
        )
      })
      .addCase(employeePlatformActions.topUpCardSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Card Top Up Success',
            'You have successfully topped up your Fleet card. Your wallet balance should update shortly.'
          )
        )
      })

      /* -- EMPLOYEE PLATFORM CARPOOL -- */
      .addCase(employeePlatformActions.createCarpoolGroupSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Carpool Group Created',
            'Your carpool group has been successfully created.'
          )
        )
      })
      .addCase(employeePlatformActions.requestToJoinCarpoolSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Request Sent',
            'Your request to join the carpool has been sent.'
          )
        )
      })
      .addCase(employeePlatformActions.acceptCarpoolRequestSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Request Accepted',
            'You have successfully accepted the request to join your carpool.'
          )
        )
      })
      .addCase(
        employeePlatformActions.declineCarpoolRequestSuccess,
        (state) => {
          return addNotification(
            state,
            generateNotificationData(
              'Request Declined',
              'You have successfully declined the request to join your carpool.'
            )
          )
        }
      )

      /* -- EMPLOYEE PLATFORM TRIPS -- */
      .addCase(employeePlatformActions.addTripSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Trip Added',
            'You have successfully added a trip.'
          )
        )
      })

      /* -- EMPLOYEE PLATFORM OPT-IN -- */
      .addCase(employeePlatformActions.setOptInChoiceSuccess, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Opt-In Choice Set',
            'You have successfully set your opt-in choice.'
          )
        )
      })

      /* -- FAILED ACTIONS -- */
      .addMatcher(isFailedAction, (state) => {
        return addNotification(
          state,
          generateNotificationData(
            'Whoops! Something went wrong.',
            'Please try again or contact support.',
            'alert'
          )
        )
      })
  },
})

export const notificationsReducer = notificationsSlice.reducer
export const notificationsActions = { ...notificationsSlice.actions }
