import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SegmentParams } from 'redux/employer/employerTypes'
import { programsActions } from 'redux/programs/programsSlice'
import {
  CardProgram,
  Merchant,
  ProgramServiceUsage,
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import {
  GrhDashboardStats,
  GrhGroup,
  GrhProgram,
  GrhProgramUpdate,
  GrhServiceUsage,
  GrhUsageHistory,
} from './grhTypes'
import ProgramUtils from 'core-system/Program/ProgramUtils'

export interface GrhState {
  grhPrograms: Dictionary<GrhGroup>
  grhProgramUpdated: boolean
  grhProgramsLoaded: boolean

  dashboardStats: GrhDashboardStats
  serviceUsage: ProgramServiceUsage[]
  availableMerchants: {
    rideshare: Merchant[]
    localTaxi: Merchant[]
  }
  usageHistory: GrhUsageHistory[]
}

export const initialState: GrhState = {
  grhPrograms: null,
  grhProgramUpdated: false,
  grhProgramsLoaded: false,

  dashboardStats: null,
  serviceUsage: null,
  availableMerchants: null,
  usageHistory: null,
}

const handleToggleProgramUpdate = (state: GrhState) => {
  if (!state.grhProgramUpdated) {
    return {
      ...state,
      grhProgramUpdated: !state.grhProgramUpdated,
      grhProgramsLoaded: false,
    }
  }

  return {
    ...state,
    grhProgramUpdated: !state.grhProgramUpdated,
  }
}

const createGrhProgram = createAction<GrhProgram>('grh/createGrhProgram')
const createGrhProgramSuccess = createAction('grh/createGrhProgramSuccess')

const updateGrhProgram = createAction<GrhProgramUpdate>('grh/updateGrhProgram')

const cancelGrhProgram = createAction<string>('grh/cancelGrhProgram')

const grhSlice = createSlice({
  name: 'grh',
  initialState,
  reducers: {
    updateGrhProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    cancelGrhProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    toggleGrhProgramUpdated(state) {
      return handleToggleProgramUpdate(state)
    },
    getGrhRecommendations(state, _action: PayloadAction<SegmentParams>) {
      state.availableMerchants = null
    },
    getGrhRecommendationsSuccess(state, action: any) {
      const groupings = action.payload.reduce(
        (modeGroupings: GrhState['availableMerchants'], merchant: Merchant) => {
          if (!merchant.pricingTypes.includes('CBC')) {
            if (
              ProgramUtils.taxiProviderNames.includes(
                merchant.name.toLowerCase()
              )
            ) {
              modeGroupings.localTaxi.push(merchant)
            } else {
              modeGroupings.rideshare.push(merchant)
            }
          }
          return modeGroupings
        },
        { rideshare: [], localTaxi: [] }
      )
      state.availableMerchants = groupings
    },
    getGrhDashboardStats(state, _action: PayloadAction<SegmentAndType>) {
      state.dashboardStats = null
    },
    getGrhDashboardStatsSuccess(state, action: any) {
      const payload = action.payload
      state.dashboardStats = {
        ...state.dashboardStats,
        totalSpend: payload.totalSpend.total,
        totalSpendDifference: payload.totalSpend.difference,
        numEmployees: payload.engagement,
        numEmployeesDifference: payload.engagementDifference,
      }
    },
    getGrhDashboardServices(
      state,
      _action: PayloadAction<SegmentAndTypeAndDate>
    ) {
      state.serviceUsage = null
    },
    getGrhDashboardServiceSuccess(
      state,
      action: PayloadAction<GrhServiceUsage[]>
    ) {
      state.serviceUsage = action.payload
    },
    getGrhUsageHistory(state, _action: PayloadAction<SegmentParams>) {
      state.usageHistory = null
    },
    getGrhUsageHistorySuccess(
      state,
      action: PayloadAction<GrhUsageHistory[]> | any
    ) {
      state.usageHistory = action.payload
      state.dashboardStats = {
        ...state.dashboardStats,
        numTransactions: action.payload.length,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      programsActions.getAllProgramsSuccess,
      (state, action: PayloadAction<CardProgram[]>) => {
        const activeStatuses = ['ACTIVE', 'UPDATING']
        const groupedProgramsById = action.payload
          .filter((p) => p.type === 'GRH')
          .reduce((agg, program) => {
            if (program.status === 'CANCELLED') return agg

            const defaultProgramGroup = {} as any
            const formattedProgram = {
              ...program,
              budget: parseInt(program.budget),
            }

            if (activeStatuses.includes(program.status)) {
              defaultProgramGroup.active = formattedProgram
            } else {
              defaultProgramGroup.nextMonth = formattedProgram
            }

            agg[program.segment] = {
              ...agg[program.segment],
              ...defaultProgramGroup,
            }

            return agg
          }, {})
        state.grhPrograms = groupedProgramsById
        state.grhProgramsLoaded = true
      }
    )
  },
})

export const grhReducer = grhSlice.reducer
export const grhActions = {
  ...grhSlice.actions,
  createGrhProgram,
  createGrhProgramSuccess,
  updateGrhProgram,
  cancelGrhProgram,
}
