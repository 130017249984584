import { AnyAction } from 'redux'
import { all, takeLatest } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { employerActions } from 'redux/employer/employerSlice'
import EmployerService from 'redux/employer/employerService'
import {
  CreateSegmentParams,
  CreateWorksiteParams,
  CurrentWorksitePayload,
  EmployerSuccessPayload,
  PaymentMethodType,
  RolloutUsersParams,
  SegmentParams,
  UpdateSegmentEmpsParams,
  UpdateSegmentParams,
  WorksiteIdParams,
  WorksiteSuccessPayload,
  ApplyDiscountParams,
} from 'redux/employer/employerTypes'
import { userActions } from 'redux/user/userSlice'
import { employeeActions } from 'redux/employee/employeeSlice'
import { Action } from 'shared/Types/Actions'

/**************************************
 * Employer
 **************************************/
export function* getEmployer(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getEmployerInfo,
    employerActions.getEmployerSuccess
  )
}

export function* updateEmployer(action: Action<EmployerSuccessPayload>) {
  yield defaultFlow(
    action,
    EmployerService.updateEmployer,
    employerActions.updateEmployerSuccess
  )
}

export function* updatePrimaryAcctHolder(
  action: Action<EmployerSuccessPayload>
) {
  yield defaultFlow(
    action,
    EmployerService.updatePrimaryAcctHolder,
    employerActions.updatePrimaryAcctHolderSuccess
  )
}

export function* getPlatformBillingInfo(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getPlatformBillingInfo,
    employerActions.getBillingInfoSuccess
  )
}

export function* updatePlatformBillingInfo(action: Action<PaymentMethodType>) {
  yield defaultFlow(
    action,
    EmployerService.updatePlatformBillingInfo,
    employerActions.updateBillingInfoSuccess
  )
}

export function* getEmployerInvoices(action: Action) {
  yield defaultFlow(
    action,
    EmployerService.getEmployerInvoices,
    employerActions.getEmployerInvoicesSuccess
  )
}

export function* removePaymentMethod(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployerService.removePaymentMethod,
    employerActions.removePaymentMethodSuccess
  )
}

export function* sendPlaidLinkToken(
  action: Action<{ token: string; accountId: string }>
) {
  yield defaultFlow(
    action,
    EmployerService.passPlaidToken,
    employerActions.sendPlaidLinkTokenSuccess
  )
}

export function* removePlaidLink(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployerService.removePlaidLink,
    employerActions.removePlaidLinkSuccess
  )
}

export function* applyDiscount(action: Action<ApplyDiscountParams>) {
  yield defaultFlow(
    action,
    EmployerService.applyDiscount,
    employerActions.applyDiscountSuccess
  )
}

export function* updateOnboardingStep(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployerService.updateOnboardingStep,
    employerActions.updateOnboardingStepSuccess
  )
}

/**************************************
 * Segments
 **************************************/
export function* getSegments(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getSegments,
    employerActions.getSegmentsSuccess
  )
}

export function* createSegment(action: Action<CreateSegmentParams>) {
  yield defaultFlow(
    action,
    EmployerService.createSegment,
    employerActions.createSegmentSuccess
  )
}

export function* updateSegment(action: Action<UpdateSegmentParams>) {
  yield defaultFlow(
    action,
    EmployerService.updateSegment,
    employerActions.updateSegmentSuccess
  )
}

export function* deleteSegment(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    EmployerService.deleteSegment,
    employerActions.deleteSegmentSuccess
  )
}

export function* addSegmentEmployees(action: Action<UpdateSegmentEmpsParams>) {
  yield defaultFlow(
    action,
    EmployerService.addSegmentEmployees,
    employerActions.addSegmentEmployeesSuccess
  )
}

export function* removeSegmentEmployees(
  action: Action<UpdateSegmentEmpsParams>
) {
  yield defaultFlow(
    action,
    EmployerService.removeSegmentEmployees,
    employerActions.removeSegmentEmployeesSuccess
  )
}

export function* activateSegmentUpcomingAdditions(
  action: Action<UpdateSegmentEmpsParams>
) {
  yield defaultFlow(
    action,
    EmployerService.activateSegmentUpcomingAdditions,
    employerActions.activateSegmentUpcomingAdditionsSuccess
  )
}

export function* removeSegmentUpcomingRemovals(
  action: Action<UpdateSegmentEmpsParams>
) {
  yield defaultFlow(
    action,
    EmployerService.removeSegmentUpcomingRemovals,
    employerActions.removeSegmentUpcomingRemovalsSuccess
  )
}

export function* forceActivateInactiveEmployees(
  action: Action<UpdateSegmentEmpsParams>
) {
  yield defaultFlow(
    action,
    EmployerService.forceActivateInactiveEmployees,
    employerActions.forceActivateInactiveEmployeesSuccess
  )
}

export function* declineInactiveEmployees(
  action: Action<UpdateSegmentEmpsParams>
) {
  yield defaultFlow(
    action,
    EmployerService.declineInactiveEmployees,
    employerActions.declineInactiveEmployeesSuccess
  )
}

export function* rolloutUsers(action: Action<RolloutUsersParams>) {
  yield defaultFlow(
    action,
    EmployerService.rolloutUsers,
    employerActions.rolloutUsersSuccess
  )
}

/**************************************
 * Worksites
 **************************************/
export function* getWorksites(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getWorksites,
    employerActions.getWorksitesSuccess
  )
}

export function* updateWorksite(action: Action<WorksiteSuccessPayload>) {
  yield defaultFlow(
    action,
    EmployerService.updateWorksite,
    employerActions.updateWorksiteSuccess
  )
}

export function* createWorksite(action: Action<CreateWorksiteParams>) {
  yield defaultFlow(
    action,
    EmployerService.createWorksite,
    employerActions.createWorksiteSuccess
  )
}

export function* getWorksiteInviteId(action: Action<any>) {
  yield defaultFlow(
    action,
    EmployerService.getWorksiteInviteId,
    employerActions.getWorksiteInviteIdSuccess
  )
}

export function* disableWorksiteInviteId(
  action: Action<CurrentWorksitePayload>
) {
  yield defaultFlow(
    action,
    EmployerService.disableWorksiteInviteId,
    employerActions.disableWorksiteInviteIdSuccess
  )
}

export function* archiveWorksite(action: Action<WorksiteIdParams>) {
  yield defaultFlow(
    action,
    EmployerService.archiveWorksite,
    employerActions.archiveWorksiteSuccess
  )
}

export function* unarchiveWorksite(action: Action<WorksiteIdParams>) {
  yield defaultFlow(
    action,
    EmployerService.unarchiveWorksite,
    employerActions.unarchiveWorksiteSuccess
  )
}

/**************************************
 * Employer File Uploads
 **************************************/

export function* getFileUploadHistory(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getFileUploadHistory,
    employerActions.getFileUploadHistorySuccess
  )
}

/**************************************
 * HRIS Integration
 **************************************/

export function* integrateHris(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployerService.integrateHris,
    employerActions.integrateHrisSuccess
  )
}

export function* hrisSync(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.hrisSync,
    employerActions.hrisSyncSuccess
  )
}

export function* disconnectHris(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.disconnectHris,
    employerActions.disconnectHrisSuccess
  )
}

export function* getFinchWorksites(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployerService.getFinchWorksites,
    employerActions.getFinchWorksitesSuccess
  )
}

export function* syncFinchWorksiteEmployees(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployerService.syncFinchWorksiteEmployees,
    employerActions.syncFinchWorksiteEmployeesSuccess
  )
}

export function* bulkSyncFinchWorksiteEmployees(action: Action<string[]>) {
  yield defaultFlow(
    action,
    EmployerService.bulkSyncFinchWorksiteEmployees,
    employerActions.bulkSyncFinchWorksiteEmployeesSuccess
  )
}

/**************************************
 * Watchers
 **************************************/

export function* watchEmployerInfo() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      userActions.loginSuccess.type,
      employerActions.getEmployer.type,
      employerActions.sendPlaidLinkTokenSuccess.type,
    ],
    getEmployer
  )
}

export function* watchPlatformBilling() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      employerActions.getEmployer.type,
      employerActions.getBillingInfo.type,
      employerActions.sendPlaidLinkTokenSuccess.type,
      employerActions.removePaymentMethodSuccess.type,
      employerActions.removePlaidLinkSuccess.type,
    ],
    getPlatformBillingInfo
  )
}

export function* watchUpdatePlatformBilling() {
  yield takeLatest(
    [employerActions.updateBillingInfo.type],
    updatePlatformBillingInfo
  )
}

export function* watchGetEmployerInvoices() {
  yield takeLatest(
    [employerActions.getEmployerInvoices.type],
    getEmployerInvoices
  )
}

export function* watchRemovePaymentMethod() {
  yield takeLatest(
    [employerActions.removePaymentMethod.type],
    removePaymentMethod
  )
}

export function* watchSendPlaidLink() {
  yield takeLatest(
    [employerActions.sendPlaidLinkToken.type],
    sendPlaidLinkToken
  )
}

export function* watchRemovePlaidLink() {
  yield takeLatest([employerActions.removePlaidLink.type], removePlaidLink)
}

export function* watchUpdateOnboardingStep() {
  yield takeLatest(
    [employerActions.updateOnboardingStep.type],
    updateOnboardingStep
  )
}

export function* watchUpdateEmployer() {
  yield takeLatest([employerActions.updateEmployer.type], updateEmployer)
}

export function* watchUpdatePrimaryAcctHolder() {
  yield takeLatest(
    [employerActions.updatePrimaryAcctHolder.type],
    updatePrimaryAcctHolder
  )
}

export function* watchApplyDiscount() {
  yield takeLatest([employerActions.applyDiscount.type], applyDiscount)
}

export function* watchGetSegments() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      employerActions.getSegments.type,
      employerActions.deleteSegmentSuccess.type,
      employerActions.createWorksiteSuccess.type,
      employerActions.addSegmentEmployeesSuccess.type,
      employerActions.removeSegmentEmployeesSuccess.type,
      employerActions.activateSegmentUpcomingAdditionsSuccess.type,
      employerActions.removeSegmentUpcomingRemovalsSuccess.type,
      employerActions.declineInactiveEmployeesSuccess.type,
      employeeActions.archiveEmployeeSuccess.type,
      employeeActions.reactivateEmployeeSuccess.type,
      employeeActions.changeEmployeeWorksiteSuccess.type,
      employerActions.archiveWorksiteSuccess.type,
      employerActions.uploadFileSuccess.type,
      employerActions.unarchiveWorksiteSuccess.type,
      employerActions.syncFinchWorksiteEmployeesSuccess.type,
      employerActions.bulkSyncFinchWorksiteEmployeesSuccess.type,
    ],
    getSegments
  )
}

export function* watchCreateSegment() {
  yield takeLatest([employerActions.createSegment.type], createSegment)
}

export function* watchUpdateSegment() {
  yield takeLatest([employerActions.updateSegment.type], updateSegment)
}

export function* watchDeleteSegment() {
  yield takeLatest([employerActions.deleteSegment.type], deleteSegment)
}

export function* watchAddSegmentEmployees() {
  yield takeLatest(
    [employerActions.addSegmentEmployees.type],
    addSegmentEmployees
  )
}

export function* watchRemoveSegmentEmployees() {
  yield takeLatest(
    [employerActions.removeSegmentEmployees.type],
    removeSegmentEmployees
  )
}

export function* watchActivateSegmentUpcomingAdditions() {
  yield takeLatest(
    [employerActions.activateSegmentUpcomingAdditions.type],
    activateSegmentUpcomingAdditions
  )
}

export function* watchRemoveSegmentUpcomingRemovals() {
  yield takeLatest(
    [employerActions.removeSegmentUpcomingRemovals.type],
    removeSegmentUpcomingRemovals
  )
}

export function* watchForceActivateInactiveEmployees() {
  yield takeLatest(
    [employerActions.forceActivateInactiveEmployees.type],
    forceActivateInactiveEmployees
  )
}

export function* watchDeclineInactiveEmployees() {
  yield takeLatest(
    [employerActions.declineInactiveEmployees.type],
    declineInactiveEmployees
  )
}

export function* watchRolloutUsers() {
  yield takeLatest([employerActions.rolloutUsers.type], rolloutUsers)
}

export function* watchGetWorksites() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      employerActions.getWorksites.type,
      employerActions.getWorksiteInviteIdSuccess.type,
      employerActions.archiveWorksiteSuccess.type,
      employerActions.uploadFileSuccess.type,
      employerActions.unarchiveWorksiteSuccess.type,
      employeeActions.archiveEmployeeSuccess.type,
      employeeActions.reactivateEmployeeSuccess.type,
      employeeActions.changeEmployeeWorksiteSuccess.type,
      employerActions.syncFinchWorksiteEmployeesSuccess.type,
      employerActions.bulkSyncFinchWorksiteEmployeesSuccess.type,
    ],
    getWorksites
  )
}

export function* watchUpdateWorksite() {
  yield takeLatest([employerActions.updateWorksite.type], updateWorksite)
}

export function* watchCreateWorksite() {
  yield takeLatest([employerActions.createWorksite.type], createWorksite)
}

export function* watchGetWorksiteInviteId() {
  yield takeLatest(
    [
      employerActions.createWorksiteSuccess.type,
      employerActions.getWorksiteInviteId.type,
    ],
    getWorksiteInviteId
  )
}

export function* watchDisableWorksiteInviteId() {
  yield takeLatest(
    [employerActions.disableWorksiteInviteId.type],
    disableWorksiteInviteId
  )
}

export function* watchArchiveWorksite() {
  yield takeLatest([employerActions.archiveWorksite.type], archiveWorksite)
}

export function* watchUnarchiveWorksite() {
  yield takeLatest([employerActions.unarchiveWorksite.type], unarchiveWorksite)
}

export function* watchFileUploadHistory() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      employerActions.getFileUploadHistory.type,
    ],
    getFileUploadHistory
  )
}

export function* watchIntegrateHris() {
  yield takeLatest([employerActions.integrateHris.type], integrateHris)
}

export function watchHrisSync() {
  return takeLatest(
    [employerActions.hrisSync.type, employerActions.integrateHrisSuccess.type],
    hrisSync
  )
}

export function* watchDisconnectHris() {
  yield takeLatest([employerActions.disconnectHris.type], disconnectHris)
}

export function* watchGetFinchWorksites() {
  yield takeLatest(
    [employerActions.getFinchWorksites.type, userActions.getUserSuccess.type],
    getFinchWorksites
  )
}

export function* watchSyncFinchWorksiteEmployees() {
  yield takeLatest(
    [employerActions.syncFinchWorksiteEmployees.type],
    syncFinchWorksiteEmployees
  )
}

export function* watchBulkSyncFinchWorksiteEmployees() {
  yield takeLatest(
    [employerActions.bulkSyncFinchWorksiteEmployees.type],
    bulkSyncFinchWorksiteEmployees
  )
}

export default function* () {
  yield all([
    watchEmployerInfo(),

    watchGetSegments(),
    watchCreateSegment(),
    watchUpdateSegment(),
    watchDeleteSegment(),
    watchAddSegmentEmployees(),
    watchRemoveSegmentEmployees(),
    watchActivateSegmentUpcomingAdditions(),
    watchRemoveSegmentUpcomingRemovals(),
    watchForceActivateInactiveEmployees(),
    watchDeclineInactiveEmployees(),
    watchRolloutUsers(),

    watchGetWorksites(),
    watchUpdateEmployer(),
    watchUpdatePrimaryAcctHolder(),
    watchUpdateWorksite(),
    watchCreateWorksite(),
    watchArchiveWorksite(),
    watchUnarchiveWorksite(),
    watchGetWorksiteInviteId(),
    watchDisableWorksiteInviteId(),
    watchFileUploadHistory(),
    watchIntegrateHris(),
    watchHrisSync(),
    watchDisconnectHris(),
    watchGetFinchWorksites(),
    watchSyncFinchWorksiteEmployees(),
    watchBulkSyncFinchWorksiteEmployees(),
    watchPlatformBilling(),
    watchRemovePaymentMethod(),
    watchUpdatePlatformBilling(),
    watchGetEmployerInvoices(),
    watchSendPlaidLink(),
    watchRemovePlaidLink(),
    watchApplyDiscount(),
    watchUpdateOnboardingStep(),
  ])
}
