import { AxiosResponse } from 'axios'
import Request from '../config/services/Request'
import utils from '../config/services/utils'
import {
  LoginPayload,
  SocialLoginPayload,
  SocialSignupPayload,
  UserApiType,
} from './userTypes'

const login = (data: LoginPayload): Promise<AxiosResponse> => {
  const formatted = {
    email: data.email,
    password: data.password,
  }
  return Request.post('/auth/login/', formatted)
}

const logout = (): Promise<AxiosResponse> => {
  return Request.post('/auth/logout/', {})
}

const getAuthUser = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/me/')
}

const socialLogin = (args: SocialLoginPayload): Promise<AxiosResponse> => {
  const provider = args.isMsft ? 'microsoft-graph' : 'google-oauth2'
  return Request.post(`/auth/social-login/${provider}/`, {
    code: args.code,
    provider,
  })
}

const socialSignup = (args: SocialSignupPayload): Promise<AxiosResponse> => {
  return Request.post('/auth/social-signup/', {
    code: args.code,
    provider: 'google-oauth2',
  })
}

interface UpdateUserParams extends UserApiType {}

const updateUser = (data: UpdateUserParams): Promise<AxiosResponse> => {
  if (typeof data.avatar === 'string' || data.avatar === null) {
    const formatted =
      data.avatar === null ? data : utils.removeKey('avatar', data)
    return data.isAuthUser
      ? Request.put('/hr/profile/me/', formatted)
      : Request.put('/auth/user/', formatted)
  }

  const formData = utils.getFormData(data)

  return data.isAuthUser
    ? Request.put('/hr/profile/me/', formData, true)
    : Request.put('/auth/user/', formData, true)
}

const resetPassword = (email: string): Promise<AxiosResponse> => {
  return Request.post('/auth/password/reset/', { email: email })
}

const resetPasswordConfirm = (
  newPwd: string,
  confirmPwd: string,
  uid: string,
  token: string
): Promise<AxiosResponse> => {
  const data = {
    newPassword1: newPwd,
    newPassword2: confirmPwd,
    uid: uid,
    token: token,
  }
  return Request.post('/auth/password/reset/confirm/', data)
}

const activatePhysicalCard = (
  cardholderEmail: string,
  last4: number,
  expMonth: number,
  expYear: number
) => {
  return Request.post('/cards/activate-card', {
    cardholderEmail,
    last4,
    expMonth,
    expYear,
  })
}

const employeeSignUp = (employeeData: {
  email: string
  password1: string
  password2: string
  inviteCode: string
  firstName: string
  lastName: string
  address: string
  employeeCorporateId: string
  currentTransport: string
  unitNumber: string
}) => {
  return Request.post('/auth/register/wi/', employeeData)
}

const employerSignUp = (employerData: {
  email: string
  // firstName: string
  // lastName: string
  password1: string
  // password2: string
  // name: string
  // website: string
  // workforceSize: number
}) => {
  return Request.post('/auth/register/emp/', employerData)
}

const updateOnboardingStep = (step: string) => {
  return Request.put('/metrics/onboarding-step', { onboardingStep: step })
}

export default {
  login,
  logout,
  getAuthUser,
  socialLogin,
  socialSignup,
  updateUser,
  resetPassword,
  resetPasswordConfirm,
  activatePhysicalCard,
  employeeSignUp,
  employerSignUp,
  updateOnboardingStep,
}
