import Button from 'core-system/Button'
import Alert from 'core-system/Icons/Misc/Alert'
import Modal, { ModalBody, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import FinchWorksiteSelectModal from './FinchWorksiteSelectModal'

const Container = styled.div`
  padding: 1.5rem;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey3};
  border-radius: ${(props) => props.theme.pxToRem(15)};
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.primary};
`

const ImgContainer = styled.div<{ imgUrl?: string }>`
  background-image: url(${(props) => props.imgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  width: ${(props) => props.theme.pxToRem(142)};
  height: ${(props) => props.theme.pxToRem(142)};
  margin-bottom: 1.5rem;
`

const Heading = styled.div`
  ${(props) => props.theme.typography.action2};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`

const SubText = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.text.placeholder};
`

interface ManageHrisModalProps {
  providerName: string
  reAuth: boolean
  closeModal: (e: React.MouseEvent<any>) => void
  open: boolean
  openFinchConnect: () => void
  disconnectHris: (e: React.MouseEvent<any>) => void
}

const ManageHrisModal = (props: ManageHrisModalProps) => {
  const {
    providerName,
    reAuth,
    closeModal,
    open,
    openFinchConnect,
    disconnectHris,
  } = props

  const [finchWorksiteSelectModal, setFinchWorksiteSelectModal] =
    React.useState(false)

  const providerImgUrl = providerName && reAuth ? 'reconnect' : providerName

  const handleReconnect = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      openFinchConnect()
      closeModal(e)
    },
    [closeModal, openFinchConnect]
  )

  const toggleFinchWorksiteSelectModal = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement | any>) => {
      e.stopPropagation()
      setFinchWorksiteSelectModal(!finchWorksiteSelectModal)
    },
    [finchWorksiteSelectModal]
  )

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(514)}>
      <ModalHeader
        title={`Manage ${providerName} Integration`}
        style={{ textTransform: 'capitalize' }}
      />
      <ModalBody paddingBottom='3.25rem' paddingTop='1.5rem'>
        {providerName && reAuth && (
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImgContainer
                imgUrl={`${StaticAssetsUrl}/hris_provider_logos/${providerImgUrl}.png`}
              />
            </div>
            <div>
              <Heading>Please Reconnect</Heading>
              <div>
                Something went wrong please reconnect your Fleet account with{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {providerName}.
                </span>
              </div>
            </div>
            <Button
              onClick={handleReconnect}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
              iconLeft={<Alert />}
            >
              Reconnect
            </Button>
            <SubText>
              Your information is handled securely with end-to-end encryption.
            </SubText>
          </Container>
        )}
        {providerName && !reAuth && (
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImgContainer
                imgUrl={`${StaticAssetsUrl}/hris_provider_logos/${providerImgUrl}.png`}
              />
            </div>
            <div>
              <Heading>{providerName}</Heading>
              <div>
                Your Fleet account is currently connected to{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {providerName}.
                </span>
              </div>
            </div>
            <Button
              variant='primary'
              onClick={toggleFinchWorksiteSelectModal}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
            >
              Select a Worksite to Sync
            </Button>
            <FinchWorksiteSelectModal
              open={finchWorksiteSelectModal}
              closeModal={toggleFinchWorksiteSelectModal}
            />
            <Button
              variant='secondary'
              onClick={disconnectHris}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
            >
              Disconnect
            </Button>
            <SubText>
              Your information is handled securely with end-to-end encryption.
            </SubText>
          </Container>
        )}
      </ModalBody>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ManageHrisModal.displayName = 'ManageHrisModal'
}

export default ManageHrisModal
