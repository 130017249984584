import { RawDraftContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export const PreTaxProgramTypeMap = {
  COMMUTER_TRANSIT: 'Transit',
  COMMUTER_PARKING: 'Parking',
}

export const CommutePreferenceDropdownItems = [
  { id: 'CAR', text: 'Car' },
  { id: 'TRANSIT', text: 'Transit' },
  { id: 'BIKE', text: 'Bike' },
  { id: 'SCOOTER', text: 'Scooter' },
  { id: 'MOPED', text: 'Moped' },
  { id: 'CARPOOL', text: 'Carpool' },
  { id: 'MULTIMODAL', text: 'Multimodal' },
  { id: 'OTHER', text: 'Other' },
]

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const convertMapToDropdownItems = (map: Map<string, string>) => {
  return Array.from(map, ([id, text]) => ({ id, text }))
}

export const timeRanges = new Map([
  ['06:00-09:00', 'Early Morning (6:00AM-9:00AM)'],
  ['08:00-10:00', 'Mid Morning (8:00AM-10:00AM)'],
  ['09:00-12:00', 'Late Morning (9:00AM-12:00PM)'],
  ['12:00-15:00', 'Early Afternoon (12:00PM-3:00PM)'],
  ['12:00-18:00', 'Afternoon (12:00PM-6:00PM)'],
  ['14:00-16:00', 'Mid Afternoon (2:00PM-4:00PM)'],
  ['15:00-18:00', 'Late Afternoon (3:00PM-6:00PM)'],
  ['18:00-21:00', 'Evening (6:00PM-9:00PM)'],
  ['21:00-00:00', 'Late Evening (9:00PM-12:00AM)'],
  ['00:00-06:00', 'Late Night (12:00AM-06:00AM)'],
])

export const timeRangesKeys = [
  'Early Morning (6:00AM-9:00AM)',
  'Mid Morning (8:00AM-10:00AM)',
  'Late Morning (9:00AM-12:00PM)',
  'Early Afternoon (12:00PM-3:00PM)',
  'Afternoon (12:00PM-6:00PM)',
  'Mid Afternoon (2:00PM-4:00PM)',
  'Late Afternoon (3:00PM-6:00PM)',
  'Evening (6:00PM-9:00PM)',
  'Late Evening (9:00PM-12:00AM)',
  'Late Night (12:00AM-06:00AM)',
]

export const timeRangesShort = new Map([
  ['06:00-09:00', '6AM-9AM'],
  ['08:00-10:00', '8AM-10AM'],
  ['09:00-12:00', '9AM-12PM'],
  ['12:00-15:00', '12PM-3PM'],
  ['12:00-18:00', '12PM-6PM'],
  ['14:00-16:00', '2PM-6PM'],
  ['15:00-18:00', '3PM-6PM'],
  ['18:00-21:00', '6PM-9PM'],
  ['21:00-00:00', '9PM-12AM'],
  ['00:00-06:00', '12AM-6AM'],
])

export const tripTypes = {
  HOME2WORK: 'Home → Work',
  WORK2HOME: 'Work → Home',
  NOTRIP: 'No Trip Taken',
  OTHER: 'Other',
}

export const noTripReasons = {
  WFH: 'Work from Home',
  VACATION: 'Vacation',
  SICK: 'Sick',
  OTHER: 'Other',
}

export const convertMetresToMiles = (metres: number, roundTo?: number) => {
  return roundTo
    ? Math.round(metres * 0.000621371 * Math.pow(10, roundTo)) /
        Math.pow(10, roundTo)
    : Math.round(metres * 0.000621371)
}

export const convertMilesToMetres = (miles: number) => {
  return miles * 1609.34
}

type timeIntervalOption = 5 | 10 | 15 | 30
export const getTimeSelectorOptions = (timeInterval?: timeIntervalOption) => {
  const hours = Array.from(Array(24).keys(), (_, i) => i.toString())
  const minutes = timeInterval
    ? Array.from(Array(60 / timeInterval), (_, i) =>
        (i * timeInterval).toString()
      )
    : ['0']
  const optionIds = hours.reduce((ids, hour) => {
    const hourMins = minutes.map(
      (minute) => `${hour}:${minute.length === 1 ? `0${minute}` : minute}:00`
    )
    return ids.concat(hourMins)
  }, [])
  return Array.from(optionIds, (id: string, _) => ({
    id: id,
    text: convertTimeStringToAMPM(id),
  }))
}

export const rangeBoundOptions = Array.from(
  [2, 5, 10, 25],
  (num: number, _) => ({
    id: convertMilesToMetres(num).toString(),
    text: `Within ${num} miles`,
  })
)

export const convertTimeStringToAMPM = (timeString: string) => {
  const timeComponents = timeString.split(':')
  if (timeComponents.length === 3) {
    const isPM = Math.floor(Number(timeComponents[0]) / 12) === 1
    const hour = Number(timeComponents[0]) % 12
    return `${hour === 0 ? '12' : hour}:${timeComponents[1]} ${
      isPM ? 'PM' : 'AM'
    }`
  }
  return 'Invalid Time'
}

export const convertToTitleCase = (str: string | null) => {
  return str
    ? str
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
    : str
}

export const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}
