import { SelectItemProps } from 'core-system/Dropdown'
import { Segment, SegmentTypes } from 'redux/employer/employerTypes'

const segmentDropdownItems = (
  allSegments: Segment[],
  allItems?: any,
  typeFilter: SegmentTypes = null
): SelectItemProps[] => {
  return allSegments
    ? [...allSegments]
        .sort((a, b) => b.employees.length - a.employees.length)
        .filter((s) => !typeFilter || s.type === typeFilter)
        .map((segment) => {
          return {
            id: segment.id,
            text: `${
              segment.name === 'All Employees' ? 'All Commuters' : segment.name
            } (${segment.employees.length} Commuters)`,
            disabled: allItems && allItems[segment.id] ? true : false,
          }
        })
    : []
}

export default {
  segmentDropdownItems,
}
