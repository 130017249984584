import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextField from 'core-system/TextField'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import moment, { Moment } from 'moment'
import AddressAutocomplete, {
  Result,
} from 'core-system/Map/AddressAutocomplete/AddressAutocomplete'
import DayMonthYearInput from 'employee-platform/shared/components/DayMonthYearInput'
import Dropdown from 'core-system/Dropdown'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'

const commuteModes = [
  { id: '1', text: 'Car' },
  { id: '2', text: 'Transit' },
  { id: '3', text: 'Bike' },
  { id: '4', text: 'Scooter' },
  { id: '5', text: 'Moped' },
  { id: '6', text: 'Carpool' },
  { id: '7', text: 'Multimodal' },
  { id: '8', text: 'Other' },
]

// TODO: fix this such that there is no gap between the field and the drop down
const AddressInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const isOldEnough = (date: Moment) => {
  const today = moment()
  const age = today.diff(date, 'years')
  return age >= 18
}

interface EmployeeInfoFormProps {
  missingHomeAddress: boolean
  setHomeAddress: (homeAddress: string) => void
  unitNumber: string
  setUnitNumber: (unitNumber: string) => void
  missingReferredBy: boolean
  referredBy: string
  setReferredBy: (referredBy: string) => void
  missingEmployeeCorpID: boolean
  employeeCorpID: string
  setEmployeeCorpID: (employeeCorpID: string) => void
  showDOBPrompt: boolean
  dob: any
  setDOB: (dob: any) => void
  missingCommutePreference: boolean
  setCommutePreference: (commutePreference: string) => void
}

const EmployeeInfoForm = React.memo((props: EmployeeInfoFormProps) => {
  const {
    missingHomeAddress,
    setHomeAddress,
    unitNumber,
    setUnitNumber,
    missingReferredBy,
    referredBy,
    setReferredBy,
    missingEmployeeCorpID,
    employeeCorpID,
    setEmployeeCorpID,
    showDOBPrompt,
    dob,
    setDOB,
    missingCommutePreference,
    setCommutePreference,
  } = props

  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const [selectedCommuteMode, setSelectedCommuteMode] = useState(null)

  const validDOBInput = dob && dob.day && dob.month && dob.year

  const dobErrorMessage =
    validDOBInput && !isOldEnough(moment(`${dob.day}/${dob.month}/${dob.year}`))
      ? 'You must be 18 years or older to be issued a Fleet card.'
      : null
  const onValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'unitNumber') {
      setUnitNumber(value)
    } else if (name === 'referredBy') {
      setReferredBy(value)
    } else if (name === 'employeeCorpID') {
      setEmployeeCorpID(value)
    }
  }

  useEffect(() => {
    if (profileData && employeeCorpID === null) {
      setEmployeeCorpID(profileData.email.split('@')[0])
    }
  }, [profileData, employeeCorpID, setEmployeeCorpID])

  return (
    <div>
      {missingHomeAddress ? (
        <AddressInputContainer>
          <Text variant='action3' style={{ marginBottom: '0.5rem' }}>
            Home Address *
          </Text>
          {/* TODO: fix formatting issue - gap between field and drop down */}
          <AddressAutocomplete
            label={null}
            onActiveChange={(address: Result) =>
              setHomeAddress(address.address)
            }
            hasError={false}
          />
          <TextField
            placeholder='Unit / Apt Number'
            name='unitNumber'
            type='text'
            value={unitNumber}
            onChange={onValChange}
            maxLength={20}
          />
        </AddressInputContainer>
      ) : null}
      {missingReferredBy ? (
        <div>
          <Text
            variant='action3'
            style={{ marginBottom: '0.25rem', marginTop: '1.5rem' }}
          >
            Referred By
          </Text>
          <Text
            variant='body1'
            textColor={palette.text.placeholder}
            style={{ marginBottom: '0.5rem' }}
          >
            If someone referred you to Fleet, please enter their email address
            below.
          </Text>
          <TextField
            placeholder='Email'
            name='referredBy'
            type='text'
            value={referredBy}
            onChange={onValChange}
          />
        </div>
      ) : null}
      {missingEmployeeCorpID ? (
        <div>
          <Text
            variant='action3'
            style={{ marginBottom: '0.25rem', marginTop: '1.5rem' }}
          >
            Employee ID *
          </Text>
          <TextField
            placeholder='Employee ID'
            name='employeeCorpID'
            type='text'
            value={employeeCorpID}
            onChange={onValChange}
          />
        </div>
      ) : null}
      {showDOBPrompt ? (
        <div>
          <Text
            variant='action3'
            style={{ marginBottom: '0.25rem', marginTop: '1.5rem' }}
          >
            Date of Birth *
          </Text>
          <Text
            variant='body1'
            textColor={palette.text.placeholder}
            style={{ marginBottom: '1rem' }}
          >
            Your date of birth is required to issue a Fleet card for
            verification purposes only. We do not store this information, it is
            sent securely to our financial partners.
          </Text>
          <DayMonthYearInput
            date={dob}
            setDate={setDOB}
            errorMessage={dobErrorMessage}
          />
        </div>
      ) : null}
      {missingCommutePreference ? (
        <div>
          <Text
            variant='action3'
            style={{ marginBottom: '0.25rem', marginTop: '1.5rem' }}
          >
            Commute Method Preference
          </Text>
          <Dropdown
            items={commuteModes}
            active={selectedCommuteMode}
            itemCallback={(item) => {
              setSelectedCommuteMode(item.id)
              setCommutePreference(item.text)
            }}
            width='100%'
            selectorMaxHeight='150px'
            placeholder='Select Commute Method Preference'
          />
        </div>
      ) : null}
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeInfoForm.displayName = 'EmployeeInfoForm'
}

export default EmployeeInfoForm
